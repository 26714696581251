import type { Poll } from "@lagora/types";
import { supabase } from "@lagora/utils/supabase";
import type { PostgrestError } from "@supabase/supabase-js";
import { useEffect, useRef, useState } from "react";
import Card from "./Card.component";
import HorizontalList from "./HorizontalList.component";

// FIXME: factorize this component with `LastResult.component` and check supabase query
export default function LastPolls() {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<PostgrestError>();
  const [polls, setPolls] = useState<Poll[]>();

  useEffect(() => {
    const currentDatetime = new Date().toISOString();

    supabase
      .from("polls")
      .select("*")
      .limit(10)
      .lte("start_date", currentDatetime)
      .gt("end_date", currentDatetime)
      .order("end_date", { ascending: true })
      .then(({ data, error }) => {
        if (error) {
          setError(error);
        }

        if (Array.isArray(data)) {
          setPolls(data as Poll[]);
        }
      });
  }, []);

  if (error) {
    return (
      <div style={{ height: wrapperRef.current?.clientHeight }}>
        <div className="alert alert-error">
          <svg
            className="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>error</title>
            <path
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
          <span>Impossible de récupérer les derniers résultats.</span>
        </div>
      </div>
    );
  }

  return (
    <div
      className="w-[calc(100%+12px)]"
      data-testid="LastPolls"
      ref={wrapperRef}
    >
      <HorizontalList>
        {polls
          ? polls.map(
              (poll, resultIndex) =>
                poll?.name && (
                  <div
                    className="inline-block min-w-[calc(100vw-36px)] sm:min-w-96"
                    data-testid={`LastPolls__Item--${resultIndex}`}
                    key={poll?.id}
                  >
                    <Card
                      body={poll?.description}
                      href={`/polls/${poll.id}`}
                      title={poll.name}
                    />
                  </div>
                ),
            )
          : Array.from({ length: 10 }).map((_item, index) => (
              <div
                className="min-w-[calc(100vw-36px)] sm:min-w-96 h-[238px]"
                // biome-ignore lint/suspicious/noArrayIndexKey: Don't have time
                key={index}
              >
                <Card loading />
              </div>
            ))}
      </HorizontalList>
    </div>
  );
}
